import React from 'react'
import './login.css';

function Login() {
  return (
    <div className="tl__login-container section__padding">
        <h1 className="gradient__text">Login</h1>
    </div>
  )
}

export default Login